






























































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import GroupComponent from '@/mixins/group-component';
import { FacilityConfigEntityModel, FacilityConfigQueryModel } from '@/entity-model/facility-config-entity';
import FacilityConfigService from '@/service/facility-config';
import FacilityConfigAttributeService from '@/service/facility-config-attribute';
import FacilityConfigAttributeDrawer from '@/pages/dashboard/facility-v2/facility-config/facility-config-attribute-drawer.vue';
import FacilityConfigEditDialog from './facility-config-edit.vue';
import { FacilityBindType } from '@/model/enum';

@Component({
    components: {
        'facility-config-attribute-drawer': FacilityConfigAttributeDrawer,
        'facility-config-edit-dialog': FacilityConfigEditDialog
    }
})
export default class FacilityConfigListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    FacilityConfigEntityModel = FacilityConfigEntityModel;
    FacilityBindType = FacilityBindType;
    created() {
        this.initTable({
            service: FacilityConfigService,
            queryModel: new FacilityConfigQueryModel(),
            tableColumns: FacilityConfigEntityModel.getTableColumns()
        });
        this.getList();
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as FacilityConfigQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/facility-config-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/facility-config-list');
        }
    }

    attributeClick(model: FacilityConfigEntityModel) {
        (this.$refs.facilityConfigAttributeDrawer as FacilityConfigAttributeDrawer).drawAttributeOpen(model.id, false, model.name, FacilityConfigAttributeService, model.typeId);
    }
}

