

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { FormControlOptionModel } from '@/model/form-control';
import { FacilityConfigEntityModel } from '@/entity-model/facility-config-entity';
import { FacilityBindType, FacilityLinkType } from '@/model/enum';

@Component
export default class FacilityConfigEditDialog extends FormDialogComponent<FacilityConfigEntityModel> {
    formOnChange(formControlModel: FormControlOptionModel) {
        if (formControlModel.key === 'typeId') {
            const selectTypeModel = _.find(formControlModel.options, (item: any) => item.value === this.jtlForm.formModel.typeId);
            this.jtlForm.formModel.bindType = _.get(selectTypeModel, 'bindType');
            this.jtlForm.formModel.linkType = _.get(selectTypeModel, 'linkType');
            if (this.jtlForm.formModel.bindType === FacilityBindType.STATS && this.jtlForm.formModel.linkType === FacilityLinkType.DEVICE_ATTR) {
                this.jtlForm.formControlList[5].scopeId = this.jtlForm.formModel.typeId;
            }
            if (formControlModel.cascad) {
                _.forEach(this.jtlForm.formControlList, item => {
                    if (item.invisibleFunction) {
                        item.invisible = item.invisibleFunction(this.jtlForm.formModel);
                        if (!item.invisible) {
                            this.jtlForm.initFormControlOption(item);
                        }
                    }
                });
            }
        }
        this.$emit('change', formControlModel);
    }
}
